@tailwind base;
@tailwind components;
@tailwind utilities;

#root , body{
  padding: 0;
  margin: 0;
}
#editor-can > canvas {
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}
*{font-family: 'Poppins', sans-serif;}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');